.reviews { 
  background-color: $section-bg;
}

.reviews__title {
  position: relative;
  margin-bottom: 115px;

  text-align: center;
  color: $white-color;

  &::after {
    bottom: -55px;
    @extend %decor-center;
  }
}

.reviews__list {
  @include mq(mobile-only) {
    display: none;
  }

  display: flex;  
  gap: 30px;

  @include mq(tablet) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  @include mq(tablet-only) {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
}

.reviews__item {
  @include mq(tablet) {
    display: flex;
    gap: 20px;
    flex-direction: column-reverse;
    
    &:nth-child(odd) {
      flex-direction: column;
    }
  }        
}

.reviews__line {
  display: flex;
  align-items: center;

  @include mq(mobile-only) {
    margin-bottom: 20px;
  }  
}

.reviews__img {
  border-radius: 50%;
  margin-right: 22px;
}

.reviews__client {
  font-family: $font-serif;
  font-size: 30px;
  line-height: 1.3;
  color: $white-color;  
}

.reviews__desc { 
  position: relative;

  color: $white-color;

  padding: 110px 40px 50px;
  max-width: 313px;
  background-color: $reviews-desc-bg;

  &::before {
    content: '';
    position: absolute;
    @include side-sizes(32px, 26px);
    top: 50px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='32' height='26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m14.8854 3.8748-5.475 10.2-3.45.975c.4-.75.85-1.35 1.35-1.8.5-.5 1.075-.75 1.725-.75 1.45 0 2.75.6 3.9 1.8 1.2 1.15 1.8 2.65 1.8 4.5 0 1.95-.675 3.625-2.025 5.025-1.3 1.4-2.925 2.1-4.875 2.1-1.85 0-3.475-.675-4.875-2.025-1.35-1.4-2.025-3.1-2.025-5.1 0-.8.15-1.725.45-2.775.35-1.05.975-2.425 1.875-4.125l6-11.1 5.625 3.075Zm16.875 0-5.475 10.2-3.375.975c.35-.75.775-1.35 1.275-1.8.55-.5 1.125-.75 1.725-.75 1.45 0 2.775.6 3.975 1.8 1.2 1.15 1.8 2.65 1.8 4.5 0 1.95-.675 3.625-2.025 5.025-1.3 1.4-2.925 2.1-4.875 2.1-1.85 0-3.475-.675-4.875-2.025-1.35-1.4-2.025-3.1-2.025-5.1 0-.8.15-1.725.45-2.775.3-1.05.9-2.425 1.8-4.125l6.075-11.1 5.55 3.075Z' fill='%23D1B06B'/%3E%3C/svg%3E");
  }

  @include mq(tablet) {
    .reviews__item:nth-child(odd) & {
      border-top-left-radius: 40px;
    }
    
    .reviews__item:nth-child(even) & {
      border-bottom-left-radius: 40px;
    }
  }

  @include mq(desktop) {
    height: 512px;
  }
}

.swiper {
  @include mq(tablet) {
    display: none;
  }
  
  .swiper-pagination-bullet-active {
    background-color: $accent-color;
  }

  .reviews__item {    
    width: 313px;

    margin-left: auto;
    margin-right: auto;
  }

  .reviews__desc {
    height: 390px;
    margin-bottom: 40px;
    border-top-left-radius: 40px;
  }
}