// просто flex який центрує нащадків всередині батьківського елементу
@mixin flex-center(
  $display: flex,
  $align-items: center,
  $justify-content: center
) {
  display: $display;
  align-items: $align-items;
  justify-content: $justify-content;
}

// можна використати при позиціонуванні флексом по горизонталі де потрібно задати відступи між елементами
@mixin flex-gap($display: flex, $justify-content: center, $gap: 30px) {
  display: $display;
  justify-content: $justify-content; // вирівняє по центру батька елементи flex
  gap: $gap; // відступ між сусідами (не потрібно гратись з margin)
}

// можна вказати падінги не двома рядками коду а одним рядком :)
@mixin section-class($padding-top: 60px, $padding-bottom: 60px) {
  padding: $padding-top 0 $padding-bottom 0;
}

// можна вказати падінги не двома рядками коду а одним рядком :)
@mixin pg($padding-left: 0px, $padding-right: 0px) {
  padding-left: $padding-left;
  padding-right: $padding-right;
}

// просто щоб прописувати ширину і висоту
@mixin side-sizes($width, $height) {
  min-width: $width;
  width: $width;
  height: $height;
}

// дуже файна штука для медіа-запитів
@mixin mq($screen) {
  $mobile-start: 320px;
  $mobile: 480px;
  $tablet: 768px;
  $desktop: 1440px; // це брейк-поінт а не контейнер

  @if $screen==small-mobile {
    @media screen and (max-width: $mobile) {
      @content;
    }
  } @else if $screen==mobile-only {
    @media screen and (max-width: #{$tablet - 0.02}) {
      @content;
    }
  } @else if $screen==mobile {
    @media screen and (min-width: $mobile) {
      @content;
    }
  } @else if $screen==tablet-only {
    @media screen and (min-width: $tablet) and (max-width: #{$desktop - 0.02}) {
      @content;
    }
  } @else if $screen==tablet {
    @media screen and (min-width: $tablet) {
      @content;
    }
  } @else if $screen==desktop {
    @media screen and (min-width: $desktop) {
      @content;
    }
  } @else if $screen==notDesktop {
    @media screen and (max-width: #{$desktop - 0.02}) {
      @content;
    }
  } @else if $screen==retina {
    @media screen and (min-device-pixel-ratio: 2),
      screen and (-moz-min-device-pixel-ratio: 2),
      screen and (-o-min-device-pixel-ratio: 2/1),
      screen and (-webkit-min-device-pixel-ratio: 2),
      screen and (min-resolution: 192dpi),
      screen and (min-resolution: 2dppx) {
      @content;
    }
  }
}

// exemple using      @include bgimg('../images/advantages/s/icon_2', 'png');
@mixin bgimg($filelink, $type) {
  background-image: url($filelink + '.' + $type), url($filelink + '.webp');

  @include mq(retina) {
    background-image: url($filelink + '@2x.' + $type), url($filelink + '@2x.webp');
  }
}

