.header {
  position: relative;

  @include mq(mobile-only) {
    background-color: $section-bg;
  }

  &__language {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 5;
    right: 15;

    &__btn {
      visibility: visible;
      font-size: 16px;
      line-height: 1.6;
      color: $white-color;

      margin-left: auto;
      margin-left: 10px;
      // padding: 24px 71px;

      background-color: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.1);
      transition: color $time-function, border-color $time-function;

      cursor: pointer;

      &:hover,
      &:focus {
        color: $accent-color;
        border-color: $accent-color;
      }

      & input {
        display: none;
      }
    }
  }
}

.header__logo {
  display: block;

  @include mq(small-mobile) {
    width: 200px;
  }

  @include mq(mobile) {
    width: 250px;
  }

  @include mq(desktop) {
    min-width: 250px;
  }
}

.header__line {
  &:nth-child(1) {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &:nth-child(2) {
    display: none;
  }

  @include mq(tablet) {
    &:nth-child(1) {
      display: none;
      align-items: center;
      padding-top: 20px;
      padding-bottom: 10px;
      margin-bottom: 10px;

      @include mq(desktop) {
        padding-top: 30px;
        padding-bottom: 15px;
      }
    }

    &:nth-child(2) {
      display: block;
      justify-content: center;
      align-items: center;
      padding-bottom: 10px;
      border-top: 1px solid $header-line-border;
      border-bottom: 1px solid $header-line-border;
    }
  }

  @include mq(desktop) {
    &:nth-child(2) {
      display: flex;
    }
  }
}

.header__btn {
  display: none;

  @include mq(tablet) {
    display: block;
    font-size: 20px;
    line-height: 1.6;
    color: $white-color;

    margin-left: auto;
    padding: 24px 71px;

    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: color $time-function, border-color $time-function;

    &:hover,
    &:focus {
      color: $accent-color;
      border-color: $accent-color;
    }
  }

  @include mq(desktop) {
    font-size: 22px;
  }
}

.nav {
  padding: 10px;
}

.nav__list {
  display: flex;
  flex-wrap: wrap;

  @include mq(tablet) {
    align-items: center;
    // justify-content: space-around;
    gap: 20;
  }

  @include mq(desktop) {
    gap: 20;
  }
}

.nav__item {
  width: fit-content;
  display: block;

  @include mq(tablet) {}

  @include mq(desktop) {}
}

.nav__link {
  line-height: 1.5;
  color: $white-color;
  transition: color $time-function;
  display: inline-block;

  &:hover,
  &:focus {
    color: $accent-color;
  }

  @include mq(tablet) {
    font-size: 20px;
    // padding-top: 10px;
    // padding-bottom: 20px;
  }

  @include mq(desktop) {
    // padding-top: 45px;
    // padding-bottom: 48px;
  }
}

.h-contacts {
  @include mq(tablet) {
    display: flex;
    justify-content: space-around;
  }

  @include mq(desktop) {
    margin-left: auto;
    gap: 20px;
  }
}

.h-contacts__item {
  display: flex;
  align-items: center;

  @include mq(mobile-only) {
    margin-bottom: 10px;
  }
}

.h-contacts__icon {
  @include side-sizes(20px, 20px);
  @include flex-center;
  margin-right: 12px;
  color: $icon-color;

  @include mq(mobile) {
    @include side-sizes(62px, 62px);
    border: 2px solid $accent-color;
    border-radius: 50%;
  }
}

.h-contacts__link {
  position: relative;
  @extend %fade-in;
}

.h-contacts__phone {
  @include side-sizes(17px, 17px);
}

.h-contacts__mail {
  @include side-sizes(18px, 16px);
}

.h-contacts__subtitle {
  width: max-content;

  margin-bottom: 1px;
  font-size: 18px;
  line-height: 1.6;
  color: $accent-color;

  @include mq(mobile) {
    font-size: 18px;
  }
}

.h-contacts__text {
  // display: block;
  width: max-content;
  font-size: 16px;
  line-height: 1.2;
  color: $white-color;

  @include mq(mobile) {
    font-size: 16px;
  }
}

.mobile {
  @include mq(small-mobile) {
    height: calc(100vh - 108px);
  }

  @include mq(mobile) {
    height: calc(100vh - 130px);
  }

  @include mq(mobile-only) {
    position: absolute;
    left: 0;
    width: 100%;
    background-color: $section-bg;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    transition: $time-function;

    z-index: 1;

    &--active {
      @include mq(small-mobile) {
        top: 107px;
      }

      @include mq(mobile) {
        top: 129px;
      }

      visibility: visible;
      opacity: 1;
      pointer-events: initial;
    }
  }

  @include mq(tablet) {
    display: none;
  }
}

.burger {
  margin-left: auto;
  color: $white-color;
  transform: color $time-function;

  @include mq(tablet) {
    display: none;
  }

  &__icon {
    @include side-sizes(40px, 40px);

    &:hover,
    &:focus {
      color: $accent-color;
    }
  }

  &__icon-open {
    visibility: visible;
    opacity: 1;

    .burger.burger--active & {
      visibility: hidden;
      opacity: 0;
    }
  }

  &__icon-close {
    .burger.burger--active & {
      visibility: visible;
      opacity: 1;
    }

    .burger & {
      visibility: hidden;
      opacity: 0;
    }
  }
}

.nav__list {
  @include mq(mobile-only) {
    display: block;
    margin-bottom: 50px;
  }
}

.mobile .container {
  height: 90%;
  display: flex;
  flex-direction: column;
}

.mob-soc {
  margin-top: auto;
  margin-bottom: 30px;
}