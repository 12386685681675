%decor-left {
  content: '';
  position: absolute;
  @include side-sizes(95px, 44px);
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='95' height='45' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M28 18.8594h-7v7h7v-7Z' fill='%23D1B06B'/%3E%3Cpath d='M0 27.3594h95M0 17.3594h95M29.5 44.8594v-44M19.5 44.8594v-44' stroke='%23D1B06B'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' transform='translate(0 .8594)' d='M0 0h95v44H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}

%decor-center {
  content: '';
  position: absolute;
  @include side-sizes(95px, 44px);
  left: 50%;
  transform: translateX(-50%);
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='95' height='44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M0 26.5h95M0 16.5h95M52.5 44V0M42.5 44V0' stroke='%23D1B06B'/%3E%3Cpath d='M51 18h-7v7h7v-7Z' fill='%23D1B06B'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h95v44H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}

%yellov-btn {
  font-size: 16px;
  line-height: 1.6;
  color: $hero-btn-font;

  display: inline-block;

  background-color: $accent-color;

  &:hover span::after,
  &:focus span::after {
    transform: translate3d(0, 0, 0);
  }

  @include mq(tablet) {
    font-size: 18px;
  }

  @include mq(desktop) {
    font-size: 22px;
  }
}

%slide-in {
  display: inline-block;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1em;
    background-color: $hero-btn-font;
    opacity: 1;
    transition: transform $time-function;
    transform: translate3d(-100%, 0, 0);
  }
}

%fade-in {
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $white-color;
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
  }

  &:hover::after,
  &:focus::after {
    opacity: 1;
    transform: translate3d(0, 0.2em, 0);
  }
}

%trans {
  transition-duration: $time;
  transition-timing-function: $time-function;
}

%sosial-box {
  @include flex-center();
  width: 44px;
  height: 44px;
  border-radius: 50%;
  transition-property: background-color;
  @extend %trans;
  &:hover,
  &:focus {
    background-color: $accent-color;
  }
}
%sosial-icon {
  height: 20px;
  width: 20px;

  @extend %trans;
  .soclink__box:hover &,
  .soclink__box:focus & {
    fill: $white-color;
  }
}

%head-titel {
  position: relative;
  display: block;
  font-family: $font-serif;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 32px;
  line-height: 1.3;
  margin-bottom: 20px;
  @include mq(tablet) {
    font-size: 32px;
  }
  @include mq(desktop) {
    font-size: 45px;
    margin-right: 155px;
    margin-bottom: 0px;
  }
  color: $white-color;

  &::after {
    top: 130px;
    @extend %decor-center;

    @include mq(desktop) {
      display: block;
      content: '';
      position: absolute;
      top: 80px;
      left: 0;
      width: 95px;
      height: 44px;
      transform: translateX(0);

      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='95' height='45' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M28 18.8594h-7v7h7v-7Z' fill='%23D1B06B'/%3E%3Cpath d='M0 27.3594h95M0 17.3594h95M29.5 44.8594v-44M19.5 44.8594v-44' stroke='%23D1B06B'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' transform='translate(0 .8594)' d='M0 0h95v44H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    }
  }
}

%head-description {
  display: block;
  // font-family: $font-sans-serif;
  // max-width: 320px;
  text-align: center;
  font-size: 18px;
  line-height: 1.3;
  @include mq(tablet) {
    text-align: center;
    max-width: 720px;
    font-size: 20px;
  }
  @include mq(desktop) {
    max-width: none;
    font-size: 22px;
    margin-left: auto;
    margin-right: 155px;
  }
  color: $white-color;
}

%p-titel-mobile {
  font-size: 35px;
}
%p-titeltablet {
  font-size: 40px;
}

%p-titel-desktop {
  font-size: 45px;
}
