.why-we {  
  background-image: url('../images/why-we/why-we-bcg@1x.jpg');
  @include mq(retina) {
    background-image: url('../images/why-we/why-we-bcg@2x.jpg');
  }
  background-size: cover;  
  background-repeat: no-repeat; 
  
}

.why-we__list {  
  position: relative;
  padding: 20px;
  background: rgba(31, 39, 50, 0.85);
  background: linear-gradient(90deg, rgba(31, 39, 50, 0.85) 0%, rgba(31, 39, 50, 0.85) 100%); 

  @include mq(tablet) {
    display: flex;    
    gap: 20px;
  }

  @include mq(desktop) {
    padding: 80px 60px;
    gap: 364px;
  }
}

.why-we__item {
    display: flex;
  flex-direction: column;
  &:nth-child(1) {
    @include mq(mobile-only) {
      margin-bottom: 30px;
    }
  } 

  @include mq(tablet) {
    flex-basis: 50%;
  }
  
  &:first-child {
    &::after {
      @include mq(desktop) {
        content: '';
        position: absolute;
        @include side-sizes(264px, 900px);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        
        background-image: url('../images/why-we/ul_bcg_@1x.jpg');

        @include mq(retina) {
          background-image: url('../images/why-we/ul_bcg_@2x.jpg')
        }

        background-repeat: no-repeat;
        background-size: cover;           
      }
    }
  }
}

.why-we__titel { 
  position: relative;
  margin-bottom: 30px;

  font-family: $font-serif;
  font-size: 28px;
  line-height: 1.6;
  text-transform: capitalize;
  color: $white-color; 

  &::after {
    bottom: -58px;
    left: 0;
    @extend %decor-left;

    @include mq(mobile-only) {
      @include side-sizes(0px, 0px);
    }
  }

  @include mq(tablet-only) {
    height: 90px;
  }

  @include mq(tablet) {
    margin-bottom: 98px;    
  }  

  @include mq(desktop) {
    font-size: 45px;
    height: 144px;
  }
}

.why-we__description {  
  display: none;
  color: $font-second-color;

  @include mq(tablet) {
    display: block;
  }

  .why-we__item:nth-child(2) & {    
    margin-bottom: 20px;
  }
}

.why-we__accent {
  color: $accent-color;

  @include mq(tablet) {
    margin-bottom: 20px;
  }

  &:nth-child(2) {
    @include mq(mobile-only) {
      margin-bottom: 20px;
    }        
  }

  &:nth-child(4) {
    @include mq(tablet) {
      margin-top: 20px;
    }    
  }
}

.buch {

  &__list {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    margin-top: auto;
      @include mq(tablet) {
        margin-bottom: 0;


    }
    @include mq(desktop) {
      // margin-left: 0;
      // margin-right: auto;
      
    }   
  }

  &__item {    
    position: relative;
    display: flex;
    font-size: 14px;
    line-height: 1.6;
    
    margin-bottom: 40px;    

    @include mq(tablet) {
      font-size: 18px;
    }
    
    color: $white-color;

    &::after {
      content: '';
      position: absolute;
      display: block;
      top: calc(100% + 1px);
      width: 100%;
      height: 5px;
    }

    &:nth-child(1)::after {
      background-image: linear-gradient(
        to right,
        $accent-color 80%,
        $white-color 80%,
        $white-color 100%
      );
    }
    &:nth-child(2)::after {
      background-image: linear-gradient(
        to right,
        $accent-color 75%,
        $white-color 75%,
        $white-color 100%
      );
    }
    &:nth-child(3)::after {
      background-image: linear-gradient(
        to right,
        $accent-color 90%,
        $white-color 90%,
        $white-color 100%
      );
    }
  }
  &__procent {
    margin-left: auto;
  }

  &__btn {
    padding: 15px 30px 5px;
    text-align: center;
    @extend %yellov-btn;

    span {
      @extend %slide-in;
    }

    @include mq(tablet) {
      padding: 25px 71px 15px;

    }
    @include mq(desktop) {
      margin-left: 0;
      margin-right: auto;
      
    }    
  }
}
