.footer {
  background-color: $section-bg;
  padding: 50px 0;

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__soclink-titel {
    font-family: $font-sans-serif;
    font-size: 20px;
    line-height: 1.6;
    margin-left: auto;
    margin-right: auto;
    color: $accent-color;
    display: none;

    @include mq(tablet) {
      display: block;
      margin-left: auto;
      margin-right: 20px;
    }
    @include mq(desktop) {
    }
  }
  &__cops-soclink {
    @include flex-center;
    flex-direction: column-reverse;
    position: relative;
    padding-bottom: 15px;

    border-top: 1px solid $about-item-one-bg;
    border-bottom: 1px solid $about-item-one-bg;

    @include mq(tablet) {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      padding-bottom: 0px;
    }
    @include mq(desktop) {
    }
  }
  &__cops {
    display: inline-block;
    font-family: $font-sans-serif;
    font-size: 20px;
    color: $font-second-color;
    // border-bottom: 1px solid $about-item-one-bg;

    @include mq(mobile-only) {
      text-align: center;
    }
  }

  &__logIMG {
    display: block;
    width: 180px;
  }
  &__logo {
    position: relative;

    @include mq(mobile) {
      &::after,
      &::before {
        content: '';
        position: absolute;
        display: block;
        @include side-sizes(95px, 44px);

        @include mq(desktop) {
          @include side-sizes(520px, 80px);
        }
      }

      &::after {
        transform: translatey(50%);
        top: 0;
        left: 100%;
        background-image: url(../images/footer-icon-right-mini.svg);

        @include mq(desktop) {
          transform: translatey(0);
          background-image: url(../images/footer-icon-right.svg);
        }
      }

      &::before {
        transform: translatey(50%);
        top: 0;
        right: 100%;
        background-image: url(../images/footer-icon-left-mini.svg);

        @include mq(desktop) {
          transform: translatey(0);
          background-image: url(../images/footer-icon-left.svg);
        }
      }
    }
  }
}

.footer-soclink {
  margin-right: 0;
  margin-top: 16px;
  margin-bottom: 16px;

  @include flex-center();

  &__item:not(:last-child) {
    margin-right: 20px;
  }

  &__box {
    @extend %sosial-box;
    background-color: rgba(255, 255, 255, 0.1);
  }

  &__icon {
    @extend %sosial-icon;
    fill: $white-color;
  }
}

.footer__list {
  display: none;
  padding: 25px 0;

  @include mq(tablet) {
    display: grid;
    grid-template-columns: repeat(2, 360px);
    gap: 10px;
    justify-content: center;
  }

  @include mq(desktop) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.footer__item {
  display: flex;
  align-items: center;
}

.footer__icon {
  @include mq(mobile) {
    @include side-sizes(40px, 40px);
    @include flex-center;
    margin-right: 10px;
    color: $icon-color;
    border: 2px solid $accent-color;
    border-radius: 50%;
  }
}

.footer__phone {
  @include side-sizes(17px, 17px);
}

.footer__mail {
  @include side-sizes(18px, 16px);
}

.footer__clock {
  @include side-sizes(18px, 18px);
}

.footer__link {
  position: relative;
  @extend %fade-in;
}

.footer__subtitle {
  margin-bottom: 1px;
  font-size: 18px;
  line-height: 1.6;
  color: $accent-color;
}

.footer__text {
  font-size: 18px;
  line-height: 1.2;
  color: $white-color;
}
