body {
  font-family: $font-sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.4px;
  padding: 0;
  margin: 0;
}
body {
  top: 0 !important;
}

.skiptranslate {
  display: none;
  height: 0;
}

.language__img {
  cursor: pointer;
}
h1,
h2,
h3,
p {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

ul{
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  display: block;
  height: auto;
}

main {
  // max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
.container {
  padding: 0 15px;
  margin: 0 auto;  

  @include mq(mobile) {
    max-width: 480px;
  }

  @include mq(tablet) {
    max-width: 768px;
  }

  @include mq(tablet) {
    max-width: 1440px;
  }
}

.section { // * спільний клас для секцій  
  padding: 50px 0;

  @include mq(tablet) {
    padding: 100px 0;
  }

  @include mq(desktop) {
    padding: 150px 0;
  }
}

.section-title {
  font-family: $font-serif;
  font-weight: 400;
  font-size: 35px;
  line-height: 1.3;

  @include mq(tablet) {
      font-size: 40px;
    }
  
    @include mq(desktop) {
      font-size: 45px;
    }
}

.section-text {
  font-size: 16px;
  line-height: 1.6;

  @include mq(tablet) {
      font-size: 18px;
    }
  
    @include mq(desktop) {
      font-size: 20px;
    }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.overflow-hidden {
  overflow: hidden;
}

button {
  padding: 0;
  border: none;
  // font: inherit;
  color: inherit;
  background-color: transparent;
  /* отображаем курсор в виде руки при наведении; некоторые
  считают, что необходимо оставлять стрелочный вид для кнопок */
  cursor: pointer;
}

html {
  scroll-behavior: smooth;
}


