.achievements__title {
  position: relative;
  max-width: 900px;
  margin: 0 auto 95px;

  text-align: center;

  &::after {
    bottom: -55px;
    @extend %decor-center;
  }
}

.achievements__desc {
  max-width: 750px;
  margin: 0 auto 60px;

  text-align: center;
  color: $grey-color;
}

.achievements__list {
  margin-top: 95px;
  @include mq(tablet) {
    display: flex;
    gap: 30px;
    justify-content: center;
  }

  @include mq(tablet-only) {
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

.achievements__item {
  max-width: 100%;

  @include mq(mobile-only) {
    margin-left: auto;
    margin-right: auto;
  }

  @include mq(mobile) {
    width: 433px;
  }

  &:nth-child(1) .achievements__icon {
    @include side-sizes(22px, 20px);
  }

  &:nth-child(2) .achievements__icon {
    @include side-sizes(26px, 24px);
  }

  &:not(:last-child) {
    padding: 30px;
    display: flex;
    align-items: center;
    background-color: $achievements-bg;

    @include mq(mobile-only) {
      margin-bottom: 30px;
    }
  }

  &:last-child {
    padding: 26px 50px;
    background-size: cover;

    background-image: url('../images/achievements/achievements.jpg');

    @include mq(retina) {
      background-image: url('../images/achievements/achievements@2x.jpg');
    }

    @include mq(mobile) {
      padding-top: 36px;
      padding-bottom: 36px;
    }

    @include mq(tablet) {
      padding-top: 32px;
      padding-bottom: 32px;
    }

    @include mq(tablet) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
}

.achievements__right-colum {
  @include side-sizes(80px, 80px);
  @include flex-center;
  margin-right: 10px;
  flex-direction: column;

  border: 2px solid $accent-color;

  @include mq(mobile) {
    @include side-sizes(100px, 100px);
  }

  @include mq(desktop) {
    @include side-sizes(120px, 120px);
  }

  @include mq(tablet) {
    margin-right: 20px;
  }
}

.achievements__icon {
  color: $icon-color;
}

.achievements__count {
  font-size: 20px;
  line-height: 1.3;

  .achievements__item:not(:last-child) & {
    text-align: center;
    color: $about-years-font;
  }

  .achievements__item:last-child & {
    margin-bottom: 10px;

    color: $white-color;
  }

  @include mq(tablet) {
    font-size: 26px;
  }

  @include mq(desktop) {
    font-size: 30px;
  }
}

.achievements__subtitle {
  max-width: 200px;
  font-family: $font-serif;
  font-size: 20px;
  line-height: 1.4;
  color: $dark-color;

  @include mq(small-mobile) {
    max-width: 150px;
  }

  @include mq(mobile) {
    font-size: 26px;
  }
}

.achievements__text {
  max-width: 150px;
  font-size: 20px;
  line-height: 1.3;
  color: $white-color;
}
