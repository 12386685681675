.clients__title {
  position: relative;
  margin-bottom: 125px;
  text-align: center;

  &::after {
    bottom: -55px;
    @extend %decor-center;
  }
}

.clients__gallery {
  @include mq(mobile-only) {
    display: none;
  }
  display: grid;
  grid-template-columns: repeat(1, 350px);
  grid-gap: 30px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;

  @include mq(tablet) {
    grid-template-columns: repeat(2, 350px);
  }

  @include mq(desktop) {
    grid-template-columns: repeat(3, 350px);
  }
}

.gallery__item {
  position: relative;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  display: block;
  &:nth-child(2) {
    @include mq(mobile-only) {
      // display: none;
    }
  }

  &:nth-child(3) {
    @include mq(mobile-only) {
      // display: none;
    }

    @include mq(tablet-only) {
      // display: none;
    }
  }
}

.gallery__image:hover {
  transform: scale(1.03);
}

.gallery__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: transform $time-function;
}

.gallery__link {
  display: block;
  text-decoration: none;
  height: 100%;
}

.gallery__link:hover {
  cursor: zoom-in;
}

.swiper__box {
  overflow: hidden;
  @include mq(tablet) {
    display: none;
  }
  .swiper__box-pagination {
    text-align: center;
    z-index: 10;
    transition: opacity 0.3s;
    // position: absolute;
    padding-top: 10px;
    transform: translate3d(0, 0, 0);
  }
  .swiper-pagination-bullet-active {
    background-color: $accent-color;
  }

  .gallery__item {
    width: 313px;

    margin-left: auto;
    margin-right: auto;
  }

  // .reviews__desc {
  //   height: 390px;
  //   margin-bottom: 40px;
  //   border-top-left-radius: 40px;
  // }
}
