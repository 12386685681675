// * transition
$time-function: 250ms cubic-bezier(0.4, 0, 0.2, 1);
$time: 250ms;
// * fonts
$font-sans-serif: 'Montserrat Alternates', sans-serif;
$font-serif: 'Cormorant Garamond', serif;

// * colors
$icon-color: #ccaa60;

$white-color: #fff;
$dark-color: #161d27;
$grey-color: #909090;
$accent-color: #d1b06b;

$section-bg: #161d27;

$header-line-border: #4e555f;
$hero-btn-font: #1f2732;
$font-second-color: #d7d7d7;

$about-desc-font: #d7d7d7;
$about-item-one-bg: #3b434e;
$about-years-font: #1f2732;

$services-active-item-bg: #293341;

$achievements-bg: #fbfbfb;

$reviews-desc-bg: #2e3947;

$call-font-color: #1f2732;


$min-width-var: 320px