/* Please ❤ this if you like it! */

:root {
  --red: #da2c4d;
  --yellow: #f8ab37;
  --green: #2ecc71;
  --white: #ffffff;
  --grey-light: #f2f7f9;
  --grey: #ecedf3;
  --black: #080808;
  --black-blue: #1f2029;
  --black-blue-light: #353746;
  --black-blue-light-2: #404255;
  --black-blue-light-3: #4b4d64;
  --black-light: #424455;
}

/* #Progress
================================================== */

.progress-wrap {
  position: fixed;
  left: 50px;
  bottom: 50px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.2);
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.progress-wrap::after {
  position: absolute;
  font-family: 'unicons';
  content: '\25B2';
  text-align: center;
  line-height: 46px;
  font-size: 24px;
  color: var(--grey);
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 1;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.progress-wrap:hover::after {
  opacity: 0;
}
.progress-wrap::before {
  position: absolute;
  font-family: 'unicons';
  content: '\25B2';
  text-align: center;
  line-height: 46px;
  font-size: 24px;
  opacity: 0;
  background-image: linear-gradient(298deg, var(--yellow), var(--yellow));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 2;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.progress-wrap:hover::before {
  opacity: 1;
}
.progress-wrap svg path {
  fill: none;
}
.progress-wrap svg.progress-circle path {
  stroke: var(--yellow);
  stroke-width: 5;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
// body.light .progress-wrap {
// 	box-shadow: inset  0 0 0 2px rgba(0,0,0,0.2);
// }
// body.light .progress-wrap::after {
// 	color: var(--black-blue);
// }
// body.light .progress-wrap svg.progress-circle path {
// 	stroke: var(--black-blue);
// }
.switch,
.circle {
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}
.switch {
  width: 80px;
  height: 4px;
  border-radius: 27px;
  background-image: linear-gradient(298deg, var(--red), var(--yellow));
  position: fixed;
  right: 50px;
  top: 75px;
  display: block;
  margin: 0 auto;
  text-align: center;
  opacity: 1;
  z-index: 33333;
  transition: all 300ms linear;
}
.circle {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--black-blue-light-3);
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  animation: border-transform 10s linear infinite alternate forwards;
}
.circle:hover {
  box-shadow: 0 8px 8px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
}
.circle:before {
  position: absolute;
  font-family: 'unicons';
  content: '\eac1';
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  height: 40px;
  opacity: 1;
  color: var(--grey);
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}
.circle:after {
  position: absolute;
  font-family: 'unicons';
  content: '\eb8f';
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  height: 40px;
  color: var(--yellow);
  opacity: 0;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}
.switched {
}
.switched .circle {
  left: 45px;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  background: var(--black-blue);
}
.switched .circle:hover {
  box-shadow: 0 8px 8px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
}
.switched .circle:before {
  opacity: 0;
}
.switched .circle:after {
  opacity: 1;
}
