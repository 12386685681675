.contacts {
  background-color: $section-bg;
}

.contacts__wrapper {
  @include mq(tablet) {
    display: flex;
    align-items: center;
  }
  
  @include mq(tablet-only) {
    gap: 25px;
  }
}

.contacts__left {
  margin-bottom: 30px;  

  @include mq(tablet) {
    flex-basis: 50%;
  }

  @include mq(desktop) {
    flex-basis: 630px;
    margin-bottom: 0;
  }
}

.contacts__title {
  position: relative;
  margin-bottom: 96px;
  color: $white-color;  

  &::after {
    bottom: -53px;
    left: 0;
    @extend %decor-left;
  }

  @include mq(tablet-only) {
    font-size: 35px;
  }
}

.contacts__desc {
  margin-bottom: 30px;
  color: $white-color;

  @include mq(tablet) {
    margin-bottom: 69px;
  }
}

.contacts__btn {
  @extend %yellov-btn;

  padding: 25px 31px 15px;

  @include mq(desktop) {
    padding: 25px 71px 15px;
  }

  span {
    @extend %slide-in;
  }
}

.contacts__right {
  padding: 20px;
  background-color: $white-color;

  @include mq(tablet-only) {
    flex-basis: 480px;
    margin-right: auto;
  }

  @include mq(tablet) {
    margin-left: auto;
  }

  @include mq(desktop) {
    flex-basis: 630px;
    padding: 40px;
  }
}

.contacts__right-wrapper {
  padding: 35px 15px;
  border: 15px solid $about-desc-font;

  @include mq(desktop) {
    padding: 55px 35px;
  }
}

.contacts__subtitle {
  margin-bottom: 12px;
  font-family: $font-serif;
  font-weight: 400;
  font-size: 32px;
  line-height: 1.3;
  color: $call-font-color;

  @include mq(tablet) {
    font-size: 42px;
  }
}

.contacts__desc {
  .contacts__right & {
    margin-bottom: 25px;
    color: $grey-color;
  }
}

.phone {
  display: flex;
  align-items: center;
}

.phone__icon {
  @include mq(small-mobile) {
    display: none;
  }

  @include side-sizes(70px, 70px);
  @include flex-center;
  margin-right: 15px;
  background-color: $accent-color;
}

.phone__svg {
  @include side-sizes(27px, 27px);
  color: $white-color;
}

.phone__link {
  position: relative;

  @extend %fade-in;

  &::after {
    background-color: $call-font-color;
  }
}

.phone__subtitle {
  font-size: 18px;
  line-height: 1.2;
  color: $call-font-color;
}

.phone__number {
  font-size: 20px;
  line-height: 1.4;
  color: $call-font-color;

  @include mq(desktop) {
    font-size: 30px;
  }
}