.form {
  position: relative;
  background-size: cover;
  // min-width: $min-width-var;

  background-image: url(/src/images/form/background_@1x.jpg);
  background-repeat: no-repeat;
  padding: 50px 1px;

  @include mq(tablet) {
    padding: 50px 1px;
  }
  @include mq(desktop) {
    padding: 140px 40px;
  }

  &__list {
    margin-left: auto;
    margin-right: auto;
    background-color: $hero-btn-font;
    // min-width: 300px;
    padding: 30px 10px;

    @include mq(tablet) {
      padding: 50px 50px;

      // width: 720px;
    }
    @include mq(desktop) {
      width: 900px;
      padding: 100px 50px;
    }
    display: flex;
    flex-direction: column;
  }
  &__option {
    color: $hero-btn-font;
  }
  &__input {
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    width: 90%;
    height: 50px;
    font-size: 14px;
    line-height: 1.2em;
    @include mq(tablet) {
      width: 70%;
      height: 50px;
      font-size: 18px;
      line-height: 1.2em;
    }
    @include mq(desktop) {
      width: 70%;
      height: 50px;
      font-size: 22px;
      line-height: 1.2em;
    }
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    outline: none;

    transition-property: border color;
    transition-duration: 250ms;
    transition-timing-function: $time-function;
    color: $white-color;
    margin-bottom: 20px;

    &::placeholder {
      font-size: 16px;
      line-height: 1.2em;
      @include mq(tablet) {
        font-size: 18px;
        line-height: 1.2em;
      }
      @include mq(desktop) {
        font-size: 22px;
        line-height: 1.2em;
      }
      color: rgba(255, 255, 255, 0.6);
      transition-property: color;
      transition-duration: 250ms;
      transition-timing-function: $time-function;
    }
    &:hover::placeholder,
    &:focus::placeholder {
      color: $white-color;
    }
    &:hover,
    &:focus {
      border: 1px solid $white-color;
    }
  }
  &__comment {
    height: 150px;
    resize: none;
  }
  &__btn {
    display: inline-block;
    margin-top: 40px;
    width: 80%;
    height: 85px;
    font-size: 16px;
    line-height: 1.2em;
    @include mq(tablet) {
      font-size: 18px;
      line-height: 1.2em;
      width: 30%;
      height: 85px;
    }
    @include mq(desktop) {
      font-size: 22px;
      line-height: 1.2em;
      width: 30%;
      height: 85px;
    }

    background-color: $white-color;
    border-radius: 4px;
    margin-left: auto;
    margin-right: auto;

    transition-property: color, background-color;
    transition-duration: 250ms;
    transition-timing-function: $time-function;
    &:hover,
    &:focus {
      background-color: $accent-color;
    }
  }
}

.form {
  &__sucsess {
    // height: 678.562px;
    &-text {
      margin: auto;
      color: $hero-btn-font;
    }
    margin-left: auto;
    margin-right: auto;
    background-color: $accent-color;
    // min-width: 300px;
    padding: 30px 10px;

    @include mq(tablet) {
      padding: 50px 50px;

      // width: 720px;
    }
    @include mq(desktop) {
      width: 900px;
      padding: 100px 50px;
    }
    display: flex;
    flex-direction: column;
  }
}

label {
  visibility: hidden;
}
