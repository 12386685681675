@import './common';
@import './components/mobile-menu';
@import './components/header';
@import './components/hero';
@import './components/about';
@import './components/services';
@import './components/achievements';
@import './components/why-we';
@import './components/reviews';
@import './components/clients';
@import './components/contacts';
@import './components/form';
@import './components/gmaps';
@import './components/footer';
@import './components/widget';
@import './components/qwiz';
@import './components/scroll';
@import './components/team';